@font-face {
  font-family: 'domaine';
  src: local('domaine'),
       url('testdomainetext-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'calibreSB';
  src: local('calibreSB'),
       url('testcalibre-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'calibreReg';
  src: local('calibreReg'),
       url('testcalibre-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'calibreBold';
  src: local('calibreBold'),
       url('testcalibre-bold-webfont.woff') format('woff');
}
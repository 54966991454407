@tailwind base;
@tailwind components;
@tailwind utilities;
@import "/src/assets/fonts/Font.css";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px black inset;
  box-shadow: 0 0 0px 1000px black inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}